














































import { Component, Vue } from 'nuxt-property-decorator'
import { eventBus } from '~/eventBus'
import { MediaItem } from '~/model'

@Component
export default class IndexPageHowToWork extends Vue {
  items: MediaItem[] = [
    {
      title: 'Вы заполняете анкету всего 1 раз',
    },
    {
      title:
        'Вы получаете список одобренных предложений с персональными условиями',
    },
    {
      title:
        'Вы отправляете заявку на кредит только в те банки, условия которых вам подходят',
    },
  ]

  submit(): void {
    if (!this.$store.getters['anket/isValidSearchForm'])
      this.$vuetify.goTo('body')
    eventBus.$emit('submit-search-form')
  }
}











































import { Component, Vue, PropSync } from 'nuxt-property-decorator'
import { AddressSearch, Anket } from '~/model'

@Component({
  inheritAttrs: false,
})
export default class Autocomplete extends Vue {
  @PropSync('params')
  innerParams!: AddressSearch

  @PropSync('value')
  innerValue!: Partial<Anket>

  get hint(): string | null {
    return this.innerParams.result.length ? '' : 'Ничего не найдено'
  }
}






























import { Component, Vue, Watch } from 'nuxt-property-decorator'
import IndexPageIntro from '~/components/Views/IndexPage/IndexPageIntro.vue'
import SearchForm from '~/components/Form/SearchForm/SearchForm.vue'
import IndexPageCards from '~/components/Views/IndexPage/IndexPageCards.vue'
import IndexPageHowToWork from '~/components/Views/IndexPage/IndexPageHowToWork.vue'
import IndexPageCarousel from '~/components/Views/IndexPage/IndexPageCarousel.vue'
import { Anket } from '~/model'

@Component({
  components: {
    IndexPageIntro,
    SearchForm,
    IndexPageCards,
    IndexPageCarousel,
    IndexPageHowToWork,
  },
})
export default class IndexPage extends Vue {
  form: Anket = this.$store.getters['anket/form'] || {}

  @Watch('form', { deep: true })
  onChangedForm(form: Anket): void {
    this.$store.commit('anket/SET_FORM', form)
  }
}






























import { Component, Vue } from 'nuxt-property-decorator'
import { MediaItem } from '~/model'

@Component
export default class IndexPageCarousel extends Vue {
  get imageSize(): { width: number; height: number } {
    return this.$vuetify.breakpoint.xsOnly
      ? { width: 70, height: 24 }
      : { width: 142, height: 48 }
  }

  items: MediaItem[] = [
    {
      title: 'ВТБ',
      image: '1.svg',
    },
    {
      title: 'Совкомбанк',
      image: '2.svg',
    },
    {
      title: 'МТС Банк',
      image: '3.svg',
    },
    {
      title: 'Тинькофф  ',
      image: '4.svg',
    },
    {
      title: 'УБРиР',
      image: '5.svg',
    },
    {
      title: 'АТБ',
      image: '6.svg',
    },
  ]
}





















import { Component, Vue, PropSync, Prop, Watch } from 'nuxt-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class SelectField extends Vue {
  @PropSync('value')
  private innerValue?: unknown

  @Prop({ type: Boolean, default: false })
  private multiple!: boolean

  @Prop({ type: Object, default: () => ({}) })
  private menuProps!: Record<string, any>

  @Watch('innerValue', { deep: true })
  onChangedInnerValue(val: unknown): void {
    if (!val) return
    this.innerValue =
      !Array.isArray(val) && this.multiple ? new Array(val) : val
  }
}








































import { Component, Vue } from 'nuxt-property-decorator'
import { eventBus } from '~/eventBus'
import { MediaItem } from '~/model'

@Component
export default class IndexPageCards extends Vue {
  items: MediaItem[] = [
    {
      title: 'Безопасно',
      text: 'При передаче данных в банки используются современные технологии шифрования. Все банки имеют лицензии и состоят в реестре Банка России',
      image: 'p1.png',
    },
    {
      title: 'Быстро',
      text: 'На заполнение анкеты в среднем уходит 1 минута. Когда вы выберете банк, на получение решения по кредиту понадобится от 3 минут',
      image: 'p2.png',
    },
    {
      title: 'Удобно',
      text: 'Вы заполняете всего 1 анкету, а мы показываем вам уже одобренные предложения. Это удобнее, чем подавать заявки в разные банки самим',
      image: 'p3.png',
    },
    {
      title: 'Бесплатно',
      text: 'Мы не берем денег ни на каком из этапов — все бесплатно. Скрытых комиссий нет',
      image: 'p4.png',
    },
  ]

  submit(): void {
    if (!this.$store.getters['anket/isValidSearchForm'])
      this.$vuetify.goTo('body')
    eventBus.$emit('submit-search-form')
  }
}

import isNil from 'lodash/isNil'
import { Component, Vue, PropSync, Prop } from 'nuxt-property-decorator'

@Component
export default class CurrencyFieldMixin extends Vue {
  @PropSync('value', { type: Number })
  innerValue!: number

  @Prop({ type: String, default: '₽' })
  prefix!: string

  isFocused: boolean = false

  get isPrefix(): boolean {
    return this.isFocused || !isNil(this.innerValue)
  }
}

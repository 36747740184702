










import { Component, Vue } from 'nuxt-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class TextField extends Vue {}

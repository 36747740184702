import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index"},[_c('index-page-intro'),_vm._v(" "),_c(VContainer,[_c(VCard,{staticClass:"form-card",attrs:{"flat":""}},[_c(VCardText,[_c('search-form',{attrs:{"form":_vm.form},on:{"update:form":function($event){_vm.form=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var submitForm = ref.submitForm;
return [_c(VBtn,{staticClass:"btn-gradient btn-form mt-4 mt-sm-0",attrs:{"width":"100%","x-large":"","depressed":""},on:{"click":submitForm}},[_vm._v("Подобрать кредит")])]}}])})],1)],1)],1),_vm._v(" "),_c('index-page-how-to-work'),_vm._v(" "),_c('index-page-carousel'),_vm._v(" "),_c('index-page-cards')],1)}
var staticRenderFns = []

export { render, staticRenderFns }
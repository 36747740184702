import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,_vm._g(_vm._b({staticClass:"custom-autocomplete-field",attrs:{"loading":_vm.innerParams.isLoading,"items":_vm.innerParams.result,"search-input":_vm.innerParams.search,"no-filter":"","hide-no-data":"","hint":_vm.hint,"filled":"","menu-props":{
    offsetY: true,
    nudgeTop: -6,
    contentClass: 'custom-autocomplete-field-menu',
  },"hide-details":_vm.$vuetify.breakpoint.smAndDown ? 'auto' : false},on:{"update:searchInput":function($event){return _vm.$set(_vm.innerParams, "search", $event)},"update:search-input":function($event){return _vm.$set(_vm.innerParams, "search", $event)}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}}),{key:"progress",fn:function(){return [_c('div',{staticClass:"custom-autocomplete-field__progress"},[_c(VProgressLinear,{attrs:{"color":"white","striped":"","indeterminate":"","height":"60"}})],1)]},proxy:true}],null,true),model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'v-autocomplete',_vm.$attrs,false),_vm.$listeners),[_vm._l((_vm.$slots),function(_,name){return [_c('template',{slot:name},[_vm._t(name)],2)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }